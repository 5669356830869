<template>
  <div class="choose-date" :class="{'choose-date--loading': isLoading}">
    <Loader v-if="isLoading" class="choose-date__loader" />
    <date-form
      v-else
      :available-dates="availableDates"
      :chosen-date="chosenDate"
      :start-time="scheduledFromTime"
      :end-time="scheduledToTime"
      :location-name="locationName"
      @changeday="SET_CHOSEN_DATE"
    >
      <StepNavigationButtons
        @back="goBack"
        @confirm="submit"
        :disabled="!chosenDate || disableButtons"
        :disabled-back="disableButtons"
        confirm-button-text="Continue"
        back-button-text="Cancel"
      />
    </date-form>
  </div>
</template>

<script>
import {mapMutations, mapActions, mapGetters, mapState} from 'vuex';
import ChooseDate from '@/components/claim/components/carry-in-repair/CarryInRepairChooseDate';
import Loader from '@/components/shared/Loader';
import DateForm from '@/components/claim/components/carry-in-repair/CarryInAvailableDateForm';
import StepNavigationButtons from '@/components/shared/StepNavigationButtons';

export default {
  extends: ChooseDate,
  components: {
    Loader,
    DateForm,
    StepNavigationButtons,
  },
  data: () => ({
    disableButtons: false,
    isLoading: true,
  }),
  computed: {
    ...mapGetters('jobInfo', ['getByServiceJobId', 'jobInfo']),
    ...mapGetters('requestService/carryInRepair', ['locationInfo']),
    ...mapState('user', ['contractDetails']),
    claimId() {
      return this.$route.params.id;
    },
    locationName() {
      return this.locationInfo?.text || this.jobInfo.serviceProviderName;
    },
  },
  watch: {
    claimId: {
      async handler(claimId) {
        this.isLoading = true;

        const contract = this.contractDetails?.ContractBasicDetail || {};
        const PartnerCode = contract?.PartnerCode;

        const {customerRef} = await this.DECRYPT_PARAMS({
          customerRef: this.$route.query.customerRef,
        });

        await this.GET_CLAIMS_LIST({
          customerRef,
          serviceJobID: claimId,
          PartnerCode,
        });

        this.SET_JOB_INFO(this.getByServiceJobId(claimId));

        const {jobInfo} = this;

        await this.GET_DEVICE_INFO({
          customerRef,
          id: jobInfo.claimDetails.inventoryEquipmentID,
        });

        const {zipCode} = jobInfo.address;
        const {serviceProviderAccount} = jobInfo;
        await this.GET_SLOTS({zipCode, serviceProviderAccount});

        this.isLoading = false;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('user', ['DECRYPT_PARAMS']),
    ...mapActions('jobInfo', ['GET_CLAIMS_LIST']),
    ...mapMutations('jobInfo', ['SET_JOB_INFO']),
    ...mapActions('deviceInformation', ['GET_DEVICE_INFO']),
    ...mapActions('requestService', ['UPDATE_MASTER_CLAIM']),
    ...mapActions('requestService/carryInRepair', ['GET_SLOTS', 'UPDATE_SERVIFY_REQUEST_DETAILS']),
    async submit() {
      const {chosenLocation, chosenDate, scheduledFromTime, scheduledToTime} = this;
      const {zipCode, flatNo, houseNo, streetName, city, state} = this.jobInfo.address;

      const params = {
        flatNo,
        houseNo,
        streetName,
        city,
        state,
        zipcode: zipCode,
        scheduledDateTime: chosenDate,
        scheduledFromTime,
        scheduledToTime,
      };

      if (chosenLocation) {
        params.serviceLocationCode = chosenLocation;
      }

      this.disableButtons = true;

      const servifyClaimUpdated = await this.UPDATE_SERVIFY_REQUEST_DETAILS({
        claim: this.jobInfo,
        params,
      });

      if (servifyClaimUpdated) {
        const {customerRef, contractRef} = await this.DECRYPT_PARAMS({
          customerRef: this.$route.query.customerRef,
          contractRef: this.$route.query.contractRef,
        });

        if (chosenLocation) {
          const {locationName, serviceProviderAddress} = this;
          params.serviceLocationName = locationName;
          params.serviceProviderAddress = serviceProviderAddress;
        }

        const contract = this.contractDetails?.ContractBasicDetail || {};
        const PartnerCode = contract?.PartnerCode;

        await this.UPDATE_MASTER_CLAIM({
          claim: this.jobInfo,
          customerRef,
          contractRef,
          params: {
            consumerServiceRequest: params,
            PartnerCode,
          },
        });

        const {query} = this.$route;
        delete query.step;

        await this.$router.push({
          name: 'ShowJob',
          params: {
            id: this.claimId,
          },
          query,
        });
      }

      this.disableButtons = false;
    },
    replaceRouteOnCreated() {
      // const {params, query} = this.$route;
      // this.$router.replace({
      //   name: this.$route.name,
      //   params,
      //   query: {...query, step: 'location'},
      // });
    },
    goBack() {
      this.SET_CHOSEN_DATE('');
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
.choose-date {
  padding-bottom: 140px;
  &--loading {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  @include mf($gridMDBreakpoint) {
    padding-bottom: 0;
    &--loading {
      padding-bottom: 80px;
    }
  }
}
</style>
